import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Scrollspy from 'react-scrollspy'

const SideScroll = () => (
        
    <Scrollspy
        items={ ['jala_seenhaigus', 'kyynte_seenhaigus', 'kate_seenhaigus', 'kubeme_seenhaigus', 'dermatiit', 'keha_seenhaigus', 'peanaha_seenhaigus'] }
        currentClassName="active"
        className="symptom nav"
        offset={ -235 }
    >
            <li className="nav-item">
                <AnchorLink to="/#jala_seenhaigus" title="Jala seenhaigus" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#kyynte_seenhaigus" title="Küünte seenhaigus" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#kate_seenhaigus" title="Käte seenhaigus" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#kubeme_seenhaigus" title="Kubeme seenhaigus" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#dermatiit" title="Mähkmedermatiit" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#keha_seenhaigus" title="Keha seenhaigus" />
            </li>
            <li className="nav-item">
                <AnchorLink to="/#peanaha_seenhaigus" title="Peanaha seenhaigus" />
            </li>
    </Scrollspy>
    
)


export default SideScroll
