import React from 'react';

import Layout from "../components/layout"
import Image from "../components/image-comp"
import SEO from "../components/seo"

import Cloud from '../assets/svg/cloud.inline.svg'
import CloudBottom from '../assets/svg/cloudBottom.inline.svg'
import Ftu from '../assets/svg/ftu.inline.svg'
import Baby from '../assets/svg/baby.inline.svg'



import Silhouette from '../components/silhouette'

import ImazolProdPic from '../components/prod-image-comp'

import SideScroll from '../components/SideScroll'


const IndexPage = (props) => (

      <Layout location='/'>
        
        <SEO title="Avaleht" />
            <div id="intro" className="columns">
                <div className="column col-12" style={{justifyContent: 'flex-end'}} >
                    <h1 className="text-center text-bold">Uue põlvkonna seeneravim!</h1>
                </div>
                <div className="column col-6 col-md-12"  style={{justifyContent: 'flex-start'}} >
					<ImazolProdPic filename="imazol_plus_kreem.png"/>		
                </div>
                <div className="column col-6 col-md-12 hide-md"  style={{justifyContent: 'flex-start'}} >
					<ImazolProdPic filename="imazol_plus.png"/>
                </div>
            </div>
            <div className="columns">
                <div className="column col-4 hide-md" style={{paddingTop: '15rem'}}>
					<div className="imazol-plus-kreem-holder" >
							<ImazolProdPic filename="imazol_plus_kreem.png"/>
					</div>
				</div>
                <div className="column content-col col-8 col-md-12" >
                    <Cloud className="cloud" />
                    <div id="imazolplus">
                    <h3 style={{marginTop: '0'}} className="text-center text-bold">IMAZOL PLUS <br/><small>(klotrimasool+heksamidiin)</small></h3>
                    <p className="text-bold">Imazol® Plus <small>/klotrimasool+heksamidiin/</small> kreem <small>10mg+2,5mg 30g</small> on ainukene uue generatsiooni seeneravim, mis sisaldab korraga kahte toimeainet: klotrimasooli ja heksamidiini.</p>
                    <p>Klotrimasool hävitab seeneniidistiku, heksamidiin mõjub hävitavalt naha kahjulikele bakteritele, kuna ta on antiseptiline ja desinfitseeriv aine.</p>
                    <p className="text-bold">Kaks ravimit koos ühes tuubis - tugevam toime ja laiem toimespekter.</p>
                        <br/>
                    <table className="table">
                        <caption> Võrdlus tavalise klotrimasooli sisaldava ravimiga:</caption>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Nahaseened</th>
                          <th>Pärm- ja hallitusseened</th>
                          <th>Nahale kahjulikud bakterid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-bold">Imazol<span className="text-primary">Plus</span><br/><small>(klotrimasool+heksamidiin)</small></td>
                          <td>✔✔✔</td>
                          <td>✔✔✔</td>
                          <td>✔✔✔</td>
                        </tr>
                        <tr>
                          <td className="text-bold">Klotrimasool</td>
                          <td>✔✔✔</td>
                          <td>✔</td>
                          <td>✔</td>
                        </tr>
                      </tbody>
                    </table>
                        <br/>
                        <br/>
                    <p>Imazolis sisalduv klotrimasool on efektiivne mitme dermatomükoosi patogeeni vastu (<i>Trichophyton sp., Candida sp. ja Malassezia furfur</i>), mis põhjustavad ka pärmseentest tekkinud mähkmedermatiiti.</p>
                    <p>Klotrimasool on seenevastane ravim, mis kuulub triasoolide rühma. Tema toimemehhanism on takistada ergosterooli moodustumist, mis on seene rakkude seinte oluline osa. Ilma ergosteroolita seen sureb või hoitakse ära selle levik.</p>
                    <h4>Imazol® Plus <small>/klotrimasool+heksamidiin/</small> kreem <small>10mg+2,5mg 30g</small> näidustused:</h4>
                        <p>Klotrimasoolile tundlikud dermatomükoosid ja heksamidiinile tundlikud kaasuvad bakteriaalsed infektsioonid (nt interdigitaalsed mükoosid). Efektiivne: keha-, käte-, jalgade-, juustega kaetud peanaha-, genitaalpiirkonna-, sõrme ja varvaste seenhaiguse raviks. Sobib ka küünte seenhaiguse pikaajaliseks raviks.</p>
                        <p>Klotrimasool on näidustatud täiskasvanutele, noorukitele ja üle 1 kuu vanustele lastele. Imetavad emad ei tohi Imazol Plus’i (klotrimasool+heksamidiin) kasutada rindadel või nende ümbruses, et vältida lapse otsest kokkupuudet ravimiga.</p>
                        <h4>Ravi IMAZOL Plusiga (klotrimasool+heksamidiin):</h4>
                        <p>Imazol® Plus <small>/klotrimasool+heksamidiin/</small> kreem <small>10mg+2,5mg 30g</small> soovitatav annustamine:</p>
                        <li>Määritakse üks või kaks korda päevas (ideaalis hommikul või hommikul ja õhtul).</li>
                        <li>Tavaliselt piisab peopesa suuruse ala raviks 0,5 cm kreemist (sõrmeotsaühik).</li>
                        <Ftu style={{marginTop: '20px', marginBottom: '10px'}} />
                        <li>Kantakse nahale õhukese kihina ja masseeritakse põhjalikult.</li>
                        <li>Juhul, kui ägedad sümptomid kaovad 7 päeva jooksul, on soovitatav manustamist jätkata järgmise 3 nädala jooksul, et vältida uuesti nakatumist.</li>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column col-4 hide-md" >
					<div className="imazol-plus-holder">
						<ImazolProdPic filename="imazol_plus.png"/>
					</div>
				</div>
                <div className="column content-col col-8 col-md-12" >
                    <div id="imazolnahapasta">
                    <h3 className="text-center text-bold">IMAZOL NAHAPASTA<br/><small>(klotrimasool)</small></h3>
						
					<div className="col-12 show-md">
						<ImazolProdPic filename="imazol_plus"/>
					</div>
						
                    <p><b>Imazol® <small>/klotrimasool/</small> nahapasta <small>10mg/g 30g</small> on ainuke pasta vormis klotrimasool.</b></p>
                    <p>Imazol Pasta (klotrimasool) on uudne nahapasta, mis on ainuke klotrimasooli sisaldav toode nahapasta vormis ja see on parim lahendus mähkmelööbe puhul. Pasta sobib kasutamiseks üle 1 kuu vanustele lastele. See ei kata umbselt nahka nagu teised klotrimasooli ravimvormid. Imazol Pastal (klotrimasool) on väga hea kleepuvus ja ta on veega maha pestav, ta kuivatab sekreeti ja juhib soojust.</p>
                    <h4>Mis on <i>Candida</i> mähkmelööve?</h4>
                    <p><i>Candida</i> on pärmseene liik, mis kasvab kõige paremini soojal ja niiskel pinnasel. Lastel kasvab see tavaliselt mähkme alla jäävates nahavoltides. Kui tekib pärmseene ülekasv, põhjustab see löövet, mida nimetatakse <b><i>Candida</i> mähkmelööbeks</b> ehk dermatiidiks.</p>
                    <p>Ärritav mähkmedermatiit on üldmõiste nahalööbele mähkmete piirkonnas, mis on põhjustatud erinevatest nahahaigustest ja / või ärritavatest ainetest. Üldist mähkmelöövet või ärritavat mähkmedermatiiti iseloomustavad ühendatud kumerad erüteemilaigud ja ketendused nahavoltidega ümbritsetud kaarjatel pindadel.</p>
                    <Baby style={{marginTop: '10px', marginBottom: '20px'}} />
                    <p>Atoopiline dermatiit või ekseem on seotud allergilise reaktsiooniga, mis on sageli pärilik. Sedalaadi lööbed võivad ilmneda kõikjal kehal, kuid tavaliselt erinevates kohtades ja seda iseloomustab tugev sügelus.</p>
                    <p>Kandidoosist põhjustatud mähkmelöövet ravitakse näiteks Imazol Pastaga (klotrimasool). Lööve peaks kaduma mõni päev pärast ravimi kasutama hakkamist.</p>
                    <p>Infektsioonid, mis ei kao, võivad vajada retseptiravimit. Harvadel juhtudel võib tekkida ka bakteriaalne põletik.</p>
                    <h4>Imazol® <small>/Klotrimasool/</small> nahapasta <small>10mg/g 30g</small> näidustused:</h4>
                    <p>Klotrimasoolile tundlikud dermatomükoosid (põhjustatud <i>Trichophyton sp.</i>, <i>Candida sp.</i> või <i>Malassezia furfur</i> poolt), sealhulgas pärmseente poolt põhjustatud mähkmelööve.</p>
                    <p>Klotrimasool on näidustatud täiskasvanutele, noorukitele ja üle 1 kuu vanustele lastele.Võib kasutada ka raseduse ja imetamise ajal.</p>
                    <h4>Ravi Imazol nahapastaga (klotrimasool):</h4>
                    <p>Imazol® <small>/klotrimasool/</small> nahapasta <small>10mg/g 30g</small> soovitatav manustamine ja annused:</p>
                    <li>Määritakse üks või kaks korda päevas (ideaalis hommikul või hommikul ja õhtul).</li>
                    <li>Tavaliselt piisab peopesa suuruse ala raviks 0,5 cm nahapastast.</li>
                    <li>Kantakse nahale õhukese kihina ja masseeritakse põhjalikult.</li>
                    <li>Juhul, kui ägedad sümptomid kaovad 7 päeva jooksul, on soovitatav manustamist jätkata järgmise 3 nädala jooksul uuesti nakatumise vältimiseks</li>
                    <li>Mähkmedermatiidi korral võib haigusnähtude leevenemine ilmneda 3 päeva jooksul. Ravi peaks jätkuma veel 7 päeva.</li>
                    <br/>
                    <p><b>MEIE UUE GENERATSIOONI SEENERAVIMID on ainukesed klotrimasooli sisaldavad  käsimüügiravimid, mida võib kasutada alates teisest elukuust .</b></p>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column col-4 hide-md" ></div>
                <div className="column content-col col-8 col-md-12" >
                    <div id="misonseen">
                    <h3 className="text-center text-bold">Mis on seen?</h3>
                    <p>Meid ümbritsevas keskonnas on seeneliike üle 200 000.<br/>Inimesel põhjustavad haigusi nendest 400.</p>
                    <p>Nahaseened ehk dermatofüüdid on aeroobsed seened, mis tekitavad pindmisi seenhaigusi, nad on võimelised lagundama ja omastama sarvainet ning parasiteerima inimese ja looma sarvestunud kudedes.</p>
                    <p>Naha seenhaigused jaotatakse kahjustuse lokalisatsioonist lähtudes järgmiselt:</p>
                        <li>jala seenhaigus</li>
                        <li>küünte seenhaigus</li>
                        <li>käte seenhaigus</li>
                        <li>kubeme seenhaigus</li>
                        <li>keha seenhaigus</li>
                        <li>juustega kaetud peanaha seenhaigus</li>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column col-4 hide-md" >
					<div className="silho-holder">
						<p className="text-bold" style={{marginBottom: '.6rem'}} >Uuri lähemalt erinevate<br/>vaevuste kohta:</p>
							<SideScroll />
							<Silhouette />
					</div>
				</div>
                <div className="column content-col col-8 col-md-12" >
					<div id="symptomid">
                    <h3 className="text-center text-bold">Seenevormid ja nende sümptomid</h3>
                    <div id="jala_seenhaigus">
                        <h4>Jala seenhaigus</h4>
                        <p>Jala seenhaigus on väga levinud seenhaiguse vorm, Euroopas on nakatunud ligi 30% elanikkonnast. 60-80% haigestunutest  on suhkruhaiged. Nakatumist soodustavad mikrotraumad, naha matseratsioon, pidev kinniste ja õhku mitteläbilaskvate jalanõude kandmine, jalgade liighigistamine, alajäsemete verevarustushäired, immuunsupressioon, soe ja niiske kliima.</p>
                        <p>Haigestumise algusperioodil vaevused puuduvad, mõnikord esineb naha sügelemist. Esmalt kahjustub 4.–5. varba vahe, edaspidi ka teised varbavahed, tallavõlv ning taldade külgpinnad. Nahk ketendab, on valkjas, niiske ja matsereerunud (pehmenenud), jalad higistavad ja lõhnavad halvasti. Lõhna põhjustavad kahjustunud nahal kiiresti paljunevad bakterid. </p>
                        <div className="img-holder">
                            <Image filename="jala_seenhaigus" />
                            <i>Seene tagajärjel tekkinud mükoos, mis on tunginud kudedesse ja põhjustab seal pindmist, nahaalust või süsteemset haigust.</i>
                        </div>
                    </div>
                    <div id="kyynte_seenhaigus">
                        <h4>Küünte seenhaigus</h4>
                        <p>Küünte seenhaigus on dermatofüütidest põhjustatud infektsioon varba või sõrmeküüntel, kus kahjustatud on küüneplaat. Populatsioonis on haigestumus 3-13%. Lastel esineb küünte seenhaigust harva. Varbaküüntel esineb haigestumust 4-5 korda rohkem kui sõrmeküüntel. Kahjustunud küüs on kollakas, paksenenud, kergesti murduv ning küüne alla on moodustunud pehme mass. Haigus algab ühe küüne serva (ühest või mõlemast nurgast) ning levib tasapisi kogu küünele.
                        Tavaliselt kulub küüne seenhaiguse väljaravimiseks sama palju aega kui kaua küüs välja kasvab. Varbaküüne ravimiseks tuleks seega varuda 8-12 kuud, sõrmeküüne ravimiseks paar kuud.</p>
                        <div className="img-holder">
                        <Image filename="kyynte_seenhaigus" />
                        <i>Küünte seenhaigus.</i>
                        </div>
                    </div>
                    <div id="kate_seenhaigus">
                        <h4>Käte seenhaigus</h4>
                        <p>Käte seenhaigus on seenhaigus peopesades ja sõrmede palmaarpinnal, tegemist on sekundaarse infektsiooniga. Nakkus levib kätele jalgadelt või kubemevoldilt ja kandub edasi kratsimise või hügieeniprotseduuride käigus.</p>
                        <div className="img-holder">
                        <Image filename="kate_seenhaigus" />
                        <i>Nahk on kuiv ja kare, peopesa nahavagudes on näha peenehelbeline jahujas valkjas ketendus.</i>
                        </div>
                    </div>
                    <div id="kubeme_seenhaigus">
                        <h4>Kubeme seenhaigus</h4>
                        <p>Kubeme seenhaigust põevad sagedamini mehed. Soodusfaktoriks on rasvumine, liighigistamine, hügieeninõuete eiramine. Esineb mõõdukas naha sügelemine, kubemevoldis ja reite sisepinnal on näha teravapiirilised, punetavad, ketendavad laigud, mis aeglaselt laienevad. lööbe serv on sageli vallitaoline, millel on valkjas ketendus.</p>
                        <div className="img-holder">
                        <Image filename="kubeme_seenhaigus" />
                        <i>Kubeme seenhaigus.</i>
                        </div>
                    </div>
                    <div id="dermatiit">
                        <h4>Mähkmedermatiit</h4>
                        <p>Mähkmedermatiit on üldmõiste nahalööbele mähkmete piirkonnas, mis on
                        põhjustatud erinevatest nahahaigustest ja /või ärritavatest ainetest. Üldist mähkmelöövet või ärritavat mähkmedermatiiti iseloomustavad ühendatud kumerad erüteemilaigud ja ketendused nahavoltidega ümbritsetud kaarjatel pindadel.</p>
                        <div className="img-holder">
                        <Image filename="dermatiit_seenhaigus" />
                        <i>Mähkmedermatiit.</i>
                        </div>
                    </div>
                    <div id="keha_seenhaigus">
                        <h4>Keha seenhaigus</h4>
                        <p>Keha seenhaiguse korral tekivad nahale ümara kujuga, kõrgemate servadega punetavad, ketendavad, teravapiirilised naastud kõikjal kehapinnal. Tavaliselt ei ole haigestunul kaebusi, vahel häirib kosmeetiline defekt.</p>
                        <div className="img-holder">
                        <Image filename="keha_seenhaigus" />
                        <i>Keha seenhaigus.</i>
                        </div>
                    </div>
                    <div id="peanaha_seenhaigus">
                        <h4>Juustega kaetud peanaha seenhaigus</h4>
                        <p>Juustega kaetud peanaha seenhaigus on peamiselt lastel esinev  haigus. Nakkus levib otsese kontakti teel haige loomaga (kassipoeg, koerakutsikas, näriline) või inimesega, võimalik ka kaudne levik – kammide, juukseharjade jms. kaudu. Peanahale tekib ümar või ovaalne haiguskolle, millelt juuksed on murdunud, vahel esineb nõrk naha sügelemine või põletustunne kahjustatud alal.</p>
                        <div className="img-holder">
                        <Image filename="pea_seenhaigus" />
                        <i>Juustega kaetud peanaha seenhaigus.</i>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column col-4 hide-md" ></div>
                <div className="column content-col col-8 col-md-12" style={{marginBottom: '8rem'}} >
                    <div id="ravi">
                        <h3 className="text-center">Seenhaiguste ravi</h3>
                        <p>Mükooside raviks kasutatakse seentevastaseid ravimeid. Sõltuvalt nakkuse laadist võib kasutada paikset või süsteemset ravimit.</p>
                        <p>Seentevastased ravimid on näiteks: klotrimasool, mis on mitmetes käsimüügiravimites sisalduv seenevastane toimeaine. Teine näide on amfoteritsiin B, mis on tugevam ja mida kasutatakse kõige raskemate seeninfektsioonide raviks, mis on resistentsed teiste ravimite suhtes ja seda manustatakse intravenoosselt. Nahainfektsioonide raviks kasutatavad ravimid on muu hulgas asoolid: klotrimasool, ketokonasool, itrakonasool.</p>
                        <p className="text-bold">Ravi toetamine muude vahenditega (seeneravi ajal):<br/>Kõigi atoopilise dermatiidiga patsientide ravi lahutamatu osa peaks olema naha niisutamine. Kui toiduallergia testid on positiivsed, tuleks kaaluda muudatuste tegemist igapäeva toidus, samuti tuleks vältida kontakte teiste teadaolevate "vallandajatega."</p>
                        <p>Ehk siis isiklike hügeenitarvete/käterätikute/jalanõude/riiete ja muu kasutamine saunades, basseinides. Lisaks peavad niisked ruumid olema eeskujulikult  puhtad ning kindlasti  kasutada rannakingi.</p>
                        <p>Seenhaigustest hoidumiseks  ja kordusnakkuste vältimiseks on oluline: õigeaegne haiguse diagnoosimine ja ravi ning isiklik hügieen.</p>
                    </div>
                    <CloudBottom className="cloudBottom" />
                </div>
            </div>
            
      </Layout>
    
)

export default IndexPage
