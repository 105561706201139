import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Visibility from '../assets/svg/invisible.inline.svg'


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = (props) => {
  const data = useStaticQuery(graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
                childImageSharp {
                  fixed(width: 400, height: 300, cropFocus: ENTROPY) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
          }
        }
      }
  `)
  
  const [blur, setBlur] = useState(1)
  
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(props.filename);
  })
  
  if (!image) {
    return <div>Picture not found</div>
  }
              
  return <div
            className='sensitive-img'
            onClick={() => { blur === 0 ? setBlur(1) : setBlur(0) }}
            blur={blur}
            >
            <Visibility />
            <Img
                className="img-responsive"
                fixed={image.node.childImageSharp.fixed}
                imgStyle={{transform: 'scale(1.1)'}}
            />
         </div>
}

export default Image